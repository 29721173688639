import { React, useState, useEffect } from 'react';
import forwardarrow from '../img/ani.png';
import universitylogo from '../img/ani.png';
import loanimg from '../img/loan-img.svg';
import testimg from '../img/ani.png';
import rec1 from '../img/loan-img.svg';
import dimg from '../img/d-img.jpg';
import Form from 'react-bootstrap/Form';
import { MagnifyingGlass } from 'react-loader-spinner'
import galleriestab from '../img/ani.png';
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import lister1 from '../img/lister1.jpg';
import iconlister from '../img/iconlister.jpg';
import ReactReadMoreReadLess from 'react-read-more-read-less';
import Table from 'react-bootstrap/Table';
const Exam = () => {
    const { slug } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [exams, setExams] = useState([]);
    useEffect(() => {
        fetch(`https://webcrmapi.nayeedisha.in/api/exam-details/${slug}`)
            .then(response => response.json())
            .then(data => {
                setExams(data);
                setIsLoading(false);
               
            })
            .catch(error => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            });
    }, [slug]);
    // Assuming you have received the contact details string in the variable contactDetails
    const contactDetails = "Email: jeemain@nta.ac.in, Phone: +91-7703859909";

    // Regular expressions to extract email and phone number
    const emailRegex = /Email:\s*([\w.-]+@[\w.-]+\.\w+)/;
    const phoneRegex = /Phone:\s*([+\d-]+)/;

    // Extracting email and phone number from the contact details string
    const emailMatch = contactDetails.match(emailRegex);
    const phoneMatch = contactDetails.match(phoneRegex);
    //   word test limit
    const truncateWords = (text, limit) => {
        if (!text) return ''; // Check if text is not provided
        const words = text.split(' ');
        return words.slice(0, limit).join(' ');
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        arrows: true,
        className: "myCustomCarousel"
    };
    return (
        <>
            {/* section1 */}

            <div className='abt-section-news pt-5 pb-5'>


                <div className='container'>

                    <h3 className="text-white"> {exams.exam_name ? exams.exam_name : 'Exam Name'}</h3>

                    <div className='bds'></div>
                    <div id='brdrs'></div>
                    <div className='mysvgs '>
                                        <img src="" alt='Nayeedisha' />
                                        <b className='para1 text-white'>Conducting dy:<span className='ugc text-white'> {exams.conducting_body ? exams.conducting_body : 'Not Found'} </span></b>&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                    {/* <p>It is a long established fact that a reader.</p> */}


                </div>


            </div>
            

            
            {isLoading ? (
                <div className='d-flex justify-content-center py-5'>
                <MagnifyingGlass
                     visible={true}
                     height="80"
                     width="80"
                     ariaLabel="magnifying-glass-loading"
                     wrapperStyle={{}}
                     wrapperClass="magnifying-glass-wrapper"
                     glassColor="#c0efff"
                     color="#e15b64"
                  />
          </div>
            ) : (
                exams && (
                    
                    <div className='container pt-3 exam_bg' >
                { exams.eligibility ?
                        <section id='#nav-overview-tab'>
                            <div className='container mt-3'>
                                <div className="card">
                                    <div className="card-body overview">
                                        <h2>Eligibility</h2>
                                        <div className='overview-para'>
                                        <div dangerouslySetInnerHTML={{ __html: exams.eligibility || '' }} />
                                            {/* {exams.eligibility ? exams.eligibility : 'Null'} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        : null}

                        {/* How to Prepare */}
                        { exams.how_to_prepare ?
                        <section id="#nav-courses-tab">
                            <div className='container mt-3'>
                                <div className="card">
                                    <div className="card-body overview exam-bg">
                                        <h2>How to Prepare</h2>
                                        <div className='overview-para'>
                                        <div dangerouslySetInnerHTML={{ __html: exams.how_to_prepare || '' }} />
                                     

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :null
                        }
                        { exams.cut_off ?
                        <section id="#nav-courses-tab">
                            <div className='container mt-3'>
                                <div className="card">
                                    <div className="card-body overview">
                                        <h2>Cut off</h2>
                                        <div className='overview-para'>
                    
                                        <div dangerouslySetInnerHTML={{ __html: exams.cut_off || '' }} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    : null
                        }
 { exams.courses_offered  ?
                        <section id="#nav-courses-tab">
                            <div className='container mt-3'>
                                <div className="card">
                                    <div className="card-body overview exam-bg">
                                        <h2>Courses Offered</h2>
                                        <div className='overview-para'>
                                        <div dangerouslySetInnerHTML={{ __html: exams.courses_offered || '' }} />
                                            
                                     
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :null
 }
                        <hr />
                        <section id="#nav-program-tab">
                            <div className='container mt-5 mb-3'>
                                <div className="card">
                                    <div className="card-body overview">
                                        <h2 className='py-3'>{exams.exam_name} Highlights 2024</h2>
                                        <div class="table-responsive">
                                        <Table className='mt-2' striped bordered hover >
                                            <thead>
                                                <tr>

                                                    <th>Exam Details</th>

                                                    <th>Particulars</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Exam Name</td>
                                                    <td>{exams.exam_name ? exams.exam_name : 'Null'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Conducting Body</td>
                                                    <td>{exams.conducting_body ? exams.conducting_body : 'Null'}</td>
                                                </tr>

                                                <tr>

                                                    <td>Exam Level</td>
                                                    <td>{exams.exam_level ? exams.exam_level : 'Null'}</td>



                                                </tr>

                                                <tr>

                                                    <td>Exam Frequency</td>
                                                    <td>{exams.exam_frequency ? exams.exam_frequency : 'Null'}</td>



                                                </tr>

                                                <tr>

                                                    <td>Mode of Exam</td>
                                                    <td>{exams.mode_of_exam ? exams.mode_of_exam : 'Null'}</td>



                                                </tr>

                                                <tr>

                                                    <td>Courses Offered</td>
                                                    <td>{exams.courses_offered ? exams.courses_offered : 'Null'}</td>



                                                </tr>

                                                <tr>

                                                    <td>Total Seats</td>
                                                    <td>{exams.total_seats ? exams.total_seats : 'Null'}</td>



                                                </tr>
                                                <tr>

                                                    <td>Exam Duration</td>
                                                    <td>{exams.exam_duration ? exams.exam_duration : 'Null'}</td>



                                                </tr>
                                                <tr>

                                                    <td>Num of Papers</td>
                                                    <td>{exams.num_papers ? exams.num_papers : 'Null'}</td>



                                                </tr>
                                                <tr>

                                                    <td>Medium of Exam</td>
                                                    <td>{exams.medium_of_exam ? exams.medium_of_exam : 'Null'}</td>



                                                </tr>
                                                <tr>

                                                    <td>Official Website</td>
                                                    <td><a href=''>{exams.official_website ? exams.official_website : 'Null'}</a></td>
                                                </tr>
                                                <tr>
                                                    <td>Contact Details</td>
                                                    <td>
                                                        <ul className='pl-1'>
                                                            {emailMatch && <li>Email: {emailMatch[1]}</li>}
                                                            {phoneMatch && <li>Phone: {phoneMatch[1]}</li>}
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <br></br>
                    </div>

                )
            )}
        </>
    );
};
export default Exam;