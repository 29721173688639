import React, { useEffect, useRef, useState } from 'react';
import '../../../node_modules/bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper'
import Frame1 from '../../../src/pages/registration-steper/registerimage/Frame1.svg'
import Frame2 from '../../../src/pages/registration-steper/registerimage/Frame3.svg'
import Frame3 from '../../../src/pages/registration-steper/registerimage/Frame4.svg'
import Frame4 from '../../../src/pages/registration-steper/registerimage/Frame5.svg'
import Frame5 from '../../../src/pages/registration-steper/registerimage/Frame6.svg'
import { useHistory } from 'react-router-dom';
import Frame6 from '../../../src/pages/registration-steper/registerimage/Frame7.svg'
import Form from 'react-bootstrap/Form';
import { SiReacthookform } from "react-icons/si";
import { Button } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2'
import '../../../node_modules/react-phone-input-2/lib/style.css'
import Swal from 'sweetalert2'; // 
import Spinner from 'react-bootstrap/Spinner'; // Import Spinner component from react-bootstrap
import { Checkbox, FormControlLabel } from '@mui/material';
const HorizontalLinearStepper = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(false); // Initialize loading state to false
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const formRef = useRef();
  const [statedetails, setStatedetails] = useState([]);
  const history = useHistory()
  const showTab = (n) => {
    const tabs = document.getElementsByClassName("tab");
    tabs[n].style.display = "block";

    if (n === 0) {
      document.getElementById("prevBtn").style.display = "none";
    } else {
      document.getElementById("prevBtn").style.display = "inline";
    }

    if (n === (tabs.length - 1)) {
      document.getElementById("nextBtn").innerHTML = "Submit";
    } else {
      document.getElementById("nextBtn").innerHTML = "Next";
    }

    fixStepIndicator(n);
  };

  const nextPrev = (n) => {
    const tabs = document.getElementsByClassName("tab");

    if (n === 1 && !validateForm()) return false;

    tabs[currentTab].style.display = "none";
    setCurrentTab(currentTab + n);

    if (currentTab >= tabs.length) {
      document.getElementById("regForm").submit();
      return false;
    }

    showTab(currentTab);
  };

  const validateForm = () => {
    const tabs = document.getElementsByClassName("tab");
    const inputs = tabs[currentTab].getElementsByTagName("input");

    let valid = true;
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].value === "") {
        inputs[i].className += " invalid";
        valid = false;
      }
    }

    if (valid) {
      document.getElementsByClassName("step")[currentTab].className += " finish";
    }

    return valid;
  };

  const fixStepIndicator = (n) => {
    const steps = document.getElementsByClassName("step");
    for (let i = 0; i < steps.length; i++) {
      steps[i].className = steps[i].className.replace(" active", "");
    }
    steps[n].className += " active";
  };

  // select country




  const [countrydetails, setCountrydetails] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState('');
  const [citydetails, setCitydetails] = useState([]);

  useEffect(() => {
    // Fetch list of universities
    const fetchCountrydetails = async () => {
      try {
        const response = await fetch(
          "https://webcrmapi.nayeedisha.in/api/country-details",
          {
            method: "GET",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch Country-details");
        }

        const data = await response.json();
        // Set the fetched Countrydetails in state
        setCountrydetails(data);
    
      } catch (error) {
        console.error(error);
        // Handle error
      }
    };

    fetchCountrydetails();
  }, []);



  const handleCountrySelect = (e) => {
    const selectedCountryId = e.target.value;



    const fetchStatedetails = async () => {
      try {

        const response = await fetch(
          `https://webcrmapi.nayeedisha.in/api/state-details/${selectedCountryId}`,
          {
            method: "GET",

          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch State-details");
        }

        const states = await response.json();



        setSelectedCountryId(states);

      } catch (error) {
        console.error(error);
        // Handle error
      }
    };

    fetchStatedetails();


  
  };

  // add City


  const handleStateSelect = (e) => {
    const selectedStateId = e.target.value;

   

    const fetchCitydetails = async () => {
      try {
        const response = await fetch(`https://webcrmapi.nayeedisha.in/api/city-details/${selectedStateId}`, {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error("Failed to fetch City-details");
        }

        const cities = await response.json();



        setCitydetails(cities);


      } catch (error) {
        console.error(error);
        // Handle error
      }
    };

    fetchCitydetails();


    // setSelectedStateId(selectedStateId);
  
  };

  

  const submitForm = async (e) => {
    e.preventDefault();
    var data = new FormData(e.target);
    let formData = Object.fromEntries(data.entries());
  
    // Get the selected country object based on its ID
    const selectedCountry = countrydetails.find(country => country.id === formData.country);
    // Get the selected state object based on its ID
    const selectedState = statedetails.find(state => state.id === formData.state);
    // Get the selected city object based on its ID
    const selectedCity = citydetails.find(city => city.id === formData.city);
    
    // Check if all necessary data is found
    if (selectedCountry && selectedState && selectedCity) {
      // Set the country name, state name, and city name in the form data
      formData.country = selectedCountry.name;
      formData.state = selectedState.name;
      formData.city = selectedCity.name;
    }
   // Retrieve URL from cookie
   const current_url = getCookie("url");

   // Add tenth_school_name to form data
   formData.current_url = current_url;
    try {
      setLoading(true);
  
      const response = await fetch('https://webcrmapi.nayeedisha.in/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        throw new Error(responseData.error || 'Failed to add Sent Message');
      }
  
      setLoading(false);
      setSuccessMessage(responseData.message);
      setErrorMessage(responseData.message);
    
      formRef.current.reset();
  
      // Show success message using SweetAlert2
      Swal.fire({
        title: "Success!",
        text: responseData.message,
        icon: "success"
      });
      
      // Hide success message after 5 seconds
      setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setSuccessMessage(null);
      setErrorMessage(error.message || 'Failed to submit form');
  
      // Show error message using SweetAlert2
      Swal.fire({
        title: "Error!",
        text: error.message || 'Failed to submit form',
        icon: "error",
        showConfirmButton: false,
        timer: 2500
      });
      history.push("/thank-you");
   
    }
  };

    // cookie url store
    let url = window.location.href;
    console.log("Default url:", url);
    document.cookie = `url=${url}`;
    
    let wrong = document.cookie;
    console.log("Full cookie:", wrong);
    
    function getCookie (name) {
        let value = `; ${document.cookie}`;
        let parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    
    let right = getCookie("url");
    console.log("Only url:", right);
    
    // ::::::::::::::::::::::::::::::::::::::::::::::::::::
  
  
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-4 bg-light mob-hide'>
          <div className='py-3'>
            <p className='registertext'>How NayeeDisha helps you in Admission</p>
            <div className='row mt-3'>
              <div className='col-md-6'>
                <img src={Frame1} alt="img" className='w-100' />
              </div>

              <div className='col-md-6'>
                <img src={Frame2} alt="img" className='w-100' />
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-md-6'>
                <img src={Frame3} alt="img" className='w-100' />
              </div>

              <div className='col-md-6'>
                <img src={Frame4} alt="img" className='w-100' />
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-md-6'>
                <img src={Frame5} alt="img" className='w-100' />
              </div>

              <div className='col-md-6'>
                <img src={Frame6} alt="img" className='w-100' />
              </div>
            </div>
          </div>
          <p>"Our team of seasoned professionals, well-versed in the intricacies of international education"</p>
        </div>
        <div className='col-md-8'>
          <div id="stepper1" className="bs-stepper">
            <div className="bs-stepper-header d-none">



            </div>
            <div className="bs-stepper-content mt-3">

              <b className=''><SiReacthookform className='circal' />&nbsp; We Are Here To Help ! Enquire Now</b>
              <hr></hr>
              
              <form ref={formRef} onSubmit={submitForm} id='registration'>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Full Name <spam className="spamred">*</spam></Form.Label>
                      <Form.Control type="text" name='full_name' placeholder="Full Name" />
                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Email address <spam className="spamred">*</spam></Form.Label>
                      <Form.Control type="email" name='email' placeholder="name@example.com" />
                    </Form.Group>
                  </div>
                </div>

                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Mobile Number <spam className="spamred">*</spam></Form.Label>
           
                      <PhoneInput inputProps={{ name: 'phone_number', required: true, autoFocus: true }} country={'in'} />

                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Select Country <spam className="spamred">*</spam></Form.Label>
                      {countrydetails.length > 0 ? (
                        <select
                          className="form-select select-bordered w-full max-w-lg"
                          name="country"
                          onChange={handleCountrySelect}
                        >
                          <option disabled selected>
                            Select Country
                          </option>
                          {countrydetails.map((country) => (
                            <option
                              key={country.id ? country.id : "Null"}
                              value={country.id ? country.id : "Null"}
                            >
                              {country.name ? country.name : "Null"}
                            </option>
                          ))}
                          <option value="">Other</option>
                        </select>
                      ) : (
                        <div className="label">
                          <span className="label-text didable">Select Country*</span>

                        </div>
                      )}
                    </Form.Group>

                  </div>
                </div>




                <div className='row mt-3'>

                  <div className='col-md-6'>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Select State <spam className="spamred">*</spam></Form.Label>


                      {selectedCountryId?.length > 0 ? (
                        // <select
                        //   className="select select-bordered w-full max-w-lg"

                        //   name="state"
                        //   onChange={handleStateSelect}
                        //   value={selectedStateId}
                        // >
                        <select
                          className="form-select select-bordered w-full max-w-lg"

                          name="state"
                          onChange={handleStateSelect}

                        >
                          <option disabled selected>
                            Select State
                          </option>
                          {selectedCountryId.map((state) => (

                            <option
                              key={state.id ? state.id : "Null"}
                              value={state.id ? state.id : "Null"}
                            >
                              {state.name ? state.name : "Null"}
                            </option>
                          ))}

                        </select>
                      ) : (
                        <div className="text-center input input-bordered w-full w-xl max-w-lg">
                          <span className="loading loading-dots loading-lg"></span>
                        </div>
                      )}



                    </Form.Group>
                  </div>

                  <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Select City <spam className="spamred">*</spam></Form.Label>

                      {citydetails?.length > 0 ? (
                        <select
                          className="form-select select-bordered w-full max-w-lg"
                          name="city"
                        >
                          <option disabled selected>
                            Select City
                          </option>
                          {citydetails.map((city) => (
                            <option
                              key={city.id ? city.id : "Null"}
                              value={city.name ? city.name : "Null"}
                            >
                              {city.name ? city.name : "Null"}
                            </option>
                          ))}

                        </select>
                      ) : (
                        <div className="text-center input input-bordered w-full w-xl max-w-lg">
                          <span className="loading loading-dots loading-lg"></span>
                        </div>
                      )}




                    </Form.Group>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <Form.Label>Select Gender <spam className="spamred">*</spam></Form.Label>
                    <select class="form-select" aria-label="Default select example" name='Gender'>
                      <option selected>Open this select menu</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>

                  <div className='col-md-6'>
                    <Form.Label>Select Course<spam className="spamred">*</spam></Form.Label>
                    <select class="form-select" aria-label="Default select example" name='course_interested'>
                      <option selected>Please Select Preferred Stream</option>   
                      <option value="MBBS">MBBS</option> 
                      <option value="BDS">BDS</option> 
                      <option value="Bio-Technology BSC">Bio-Technology BSC</option>                    
                      <option value="Commerce and Banking">Commerce and Banking</option>
                      <option value="Design">Design</option>
                      <option value="Engineering">Engineering</option>
                      <option value="Management">Management</option>
                      <option value="Hotel Management">Hotel Management</option>
                      <option value="Information Technology">Information Technology</option>
                      <option value="Media and Mass Communication">Media and Mass Communication</option>
                      <option value="Medical">Medical</option>
                      <option value="Arts and Humanities">Arts and Humanities</option>
                      <option value="Law">Law</option>
                      <option value="Science">Science</option>
                      <option value="Vocational">Vocational</option>
                      <option value="Education">Education</option>
                      <option value="Paramedical">Paramedical</option>
                      <option value="Agriculture">Agriculture</option>
                      <option value="Nursing">Nursing</option>
                      <option value="Pharmacy">Pharmacy</option>
                    
                    </select>
                  </div>
                </div>
                <div>
                <FormControlLabel
      className='mt-2'
      required
      control={<Checkbox />}
      label={
        <span>
          I agree to the{' '}
          <a href="https://nayeedisha.in/terms-and-conditions" target="_blank">
            Terms of Service
          </a>{' '}
          and{' '}
          <a href="https://nayeedisha.in/privacy-policy" target="_blank">
            Privacy Policy
          </a>.
        </span>
      }
    />
                  <div className='mt-3 w-20 submit_button mb-3'>
               
                    {/* Disable the button and show loading spinner when loading is true */}
                    <button type="submit" className="btn btn-primary btn-accent" disabled={loading}>
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Loading...</span>
                        </>
                      ) : "Submit"}
                    </button>
           
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default HorizontalLinearStepper;
