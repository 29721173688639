import { React, useState, useRef, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ctr from '../img/ctr.png';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import Spinner from 'react-bootstrap/Spinner'; // Import Spinner component from react-bootstrap
import Swal from 'sweetalert2'; // Import SweetAlert2
import { Helmet } from 'react-helmet';

const Contactus = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false); // Initialize loading state to false
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const formRef = useRef(null);
  const phoneInputRef = useRef(null);

  useEffect(() => {
    if (successMessage) {
      Swal.fire({
        title: "Success!",
        text: successMessage,
        icon: "success"
      });
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage) {
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error"
      });
    }
  }, [errorMessage]);

  const validateEmail = (email) => {
    // Regular expression for email validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    var data = new FormData(e.target);
    let formData = Object.fromEntries(data.entries());

    if (!validateEmail(formData.email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    try {
      setLoading(true);

      const response = await fetch('https://webcrmapi.nayeedisha.in/api/contact-us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.error || 'Failed to add Sent Message');
      }

      setLoading(false);

      setSuccessMessage(responseData.message);
      setErrorMessage(null);
      history.push("/contact-us");
      formRef.current.reset();
      if (phoneInputRef.current) {
        phoneInputRef.current.clear();
      }



      // Show success message using SweetAlert2
      Swal.fire({
        title: "Success!",
        text: responseData.message,
        icon: "success"
      });

      // Hide success message after 5 seconds
      setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setSuccessMessage(null);
      setErrorMessage(error.message);

      // Show error message using SweetAlert2
      Swal.fire({

        title: "Error!",
        text: 'error.message ',
        icon: "error",
        showConfirmButton: false,
        timer: 1500
      });

    }
  };
  return (
    <>

      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Content Us | Get Admission for MBBS aboard 2024 -25</title>
          <meta name="description" content="Content Us | Get in touch with our counsellor and get the best possible assistance for overall counseling Nayeedisha.in" />
          <meta name="keywords"
            content="Content Us | Get in touch with our counsellor and get the best possible assistance for overall counseling." />
          <link rel="canonical" href="https://nayeedisha.in" />
        </Helmet>

      </div>
      {/* section1 */}
      <div className='cont-section pt-5 pb-5'>
        <div className='container'>
          <h3>Contact Us</h3>
          <div className='bds'></div>
          <div id='brdrs'></div>
        </div>
      </div>



      {/* Form */}
      <div className="container">
        <div className="row pt-5">
          <div className="col-md-6 col-12 backs-form p-5">
            <p>Have a question? Need Some Help? Or Just want to say hello?</p>
            <p>We would love to hear from you..</p>
            <h5 className='mb-3'>WE ARE LOCATED AT</h5>
            <p>Nayee Disha<br></br>
            Suite 1405, 1407, 14th Floor,T3, Techzone-4, NX One, Greater Noida West, Uttar Pradesh 201306
            </p>
            <h5 className='mb-3'>FOR ANY QUERIES CONTACT US AT</h5>
            <p>Email: <span className='unico'>info@nayeedisha.in</span></p>
            <p>Phone:<span className='unico'>+91 8860031900</span></p>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className='back-nextform p-4 '>
              <h5>Confused if this is the right college for you?<br></br> Contact with us</h5>
              <form ref={formRef} onSubmit={submitForm}>
                <div id="form" className='pt-3'>
                  <div className="form-group">
                    <InputGroup className="mb-3" type="text">
                      <Form.Control className='p-2' placeholder="Full Name" aria-label="Username" aria-describedby="basic-addon1" name='name' />
                    </InputGroup>
                  </div>
                  <div className="form-group mt-3">
                    <InputGroup className="">
                      <Form.Control className='p-2' placeholder="Email" name='email' aria-label="Username" aria-describedby="basic-addon1" />
                    </InputGroup>
                  </div>
                  <div className="form-group mt-3  ph-forms">
                    <span className="wpcf7-form-control-wrap your-name ">
                      <PhoneInput
                        inputProps={{ name: 'phone_number', required: true, autoFocus: false }} country={'in'} inputRef={phoneInputRef} />
                    </span>
                  </div>
                  <div className="form-group mt-3  ph-forms ">
                    <span className="wpcf7-form-control-wrap your-name ">
                      <textarea className='p-3' name='message' placeholder=' Message' rows={5} cols={66} />
                    </span>
                  </div>
                  <div className='mt-3'> <p>By Submitting this form you accept and agree to our <a href="terms-and-conditions">Terms Of Use</a>.</p></div>
                  <div className='mt-3 fr-but'>
                    {/* Disable the button and show loading spinner when loading is true */}
                    <button type="submit" className="btn btn-primary btn-accent" disabled={loading}>
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Loading...</span>
                        </>
                      ) : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
              <br></br>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div className='container-fluid mt-5 '>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.964995358538!2d77.42954488054171!3d28.600826922837594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef29ba0e1a4f%3A0x63538614dd1688af!2sNX%20One%20Avenue.!5e0!3m2!1sen!2sin!4v1721739117564!5m2!1sen!2sin" width="100%" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        
      </div>
    </>
  );
};

export default Contactus;
