import { React, useEffect, useState, Component, Fragment } from "react";
import { Link } from "react-router-dom";
import student from '../img/Student.png';
import counselling from '../img/counselling.jpg';
import studenter from '../img/Studenter.svg';
import countries from '../img/countries.svg';
import worlwide from '../img/worldwide.svg';
import stu from '../img/stu.svg';
import consulting from '../img/consulting.svg';
import { GoPlusCircle } from "react-icons/go";
import woman from '../img/woman.png';
import man from '../img/man.png';
import bi1 from '../img/bi-1.png';
import bi2 from '../img/bi-2.png';
import bi3 from '../img/bi-3.png';
import bi4 from '../img/bi-4.png';
import bi5 from '../img/bi-5.png';
import bi6 from '../img/bi-6.png';
import bi7 from '../img/bi-7.png';
import bi8 from '../img/bi-8.png';
import student2 from '../img/student2.svg';
import worldwide2 from '../img/worldwide2.svg';
import countries2 from '../img/countries2.svg';
import consulting2 from '../img/consulting2.svg';
import copyrimg from '../img/copyr-img.png'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import Button from 'react-bootstrap/Button';
import { DropDownCoursesData } from "../../data/dropdownData";
import { useHistory } from "react-router-dom";
import apiUrl from '../../setting/apiConfig';
import { MagnifyingGlass } from 'react-loader-spinner'
// import { Pagination, Navigation } from 'swiper/modules';
import { Helmet } from "react-helmet";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import News from "../news";
import { GrView } from "react-icons/gr";
import { IoGitCompareOutline } from "react-icons/io5";
import { MdOutlineReadMore } from "react-icons/md";
import { FaTimes } from 'react-icons/fa';
import { useComparison } from '../compare_colleges/CompareContext/CompareContext';
import CompareCollege from '../comparepage/index'
import axios from "axios";
const Home = () => {
   const history = useHistory();
   const router = useHistory()
   const [subCourse, setSubCourse] = useState([]);
   const [special, setSpecial] = useState([]);
   const [redUrl, setUrl] = useState('');
   
   const handleSubcourse = (val) => {
      const sub = DropDownCoursesData.find(x => x.value === val);
      if (sub) {
         setSubCourse(sub.subCourses)
      }
   }
   const handleSpecial = (val) => {
      const sub = subCourse.length ? subCourse.find(x => x.value === val) : undefined;
      // console.log('first---------------->', sub)
      if (sub) {
         setSpecial(sub.specialization)
      }
   }
   

   const skeletonArray = Array(4).fill(0);

   const options = {
      
      loop: true,
      margin: 10,
      items: 1,
      autoplay: true,
      responsive: {
         0: {
            items: 1,
            nav: true,
            dots: false,

         },
         600: {
            items: 2,
         },
         1000: {
            items: 4,
            nav: false,
            dots: true,
         },
      },
   };

   const optionstest = {
      loop: true,
      margin: 10,
      items: 1,
      autoplay: true,
      responsive: {
         0: {
            items: 1,
            nav: true,

         },
         600: {
            items: 3,
         },
         1000: {
            items: 3,
         },
      },
   };

   const data = {
      ugcapproved: 1 // Assuming ugcapproved holds the value
   };

   const [universities, setUniversities] = useState([]);
   const [newsdetails, setNewsdetails] = useState([]);
   const [testimonial, setTestimonial] = useState([]);
   const [exams, setExams] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [blogdetails, setBlogdetails] = useState([]);
   

  
   // compare college const [colleges, setColleges] = useState([]);
   const [colleges, setColleges] = useState([]);
   const [showDropdown, setShowDropdown] = useState([false, false, false]);
   const [selectedCollege, setSelectedCollege] = useState([null, null, null]);
   const [searchQuery, setSearchQuery] = useState(['', '', '']);
   const { setComparisonData } = useComparison();
   useEffect(() => {
       fetch('https://webcrmapi.nayeedisha.in/api/university-details')
           .then(response => response.json())
           .then(data => setColleges(data))
           .catch(error => console.error('Error fetching data:', error));
   }, []);

   const handleAddCollegeClick = (index) => {
       const newShowDropdown = showDropdown.map((value, idx) => (idx === index ? !value : value));
       setShowDropdown(newShowDropdown);
   };

   const handleCollegeChange = (index, college) => {
       if (selectedCollege.includes(college)) {
           alert('This University has already been selected.');
           return;
       }
       const newSelectedCollege = selectedCollege.map((value, idx) => (idx === index ? college : value));
       setSelectedCollege(newSelectedCollege);
       const newShowDropdown = showDropdown.map((value, idx) => (idx === index ? false : value));
       setShowDropdown(newShowDropdown);
       setSearchQuery(searchQuery.map((value, idx) => (idx === index ? '' : value)));
   };

   const handleRemoveCollege = (index) => {
       const newShowDropdown = showDropdown.map((value, idx) => (idx === index ? false : value));
       const newSelectedCollege = selectedCollege.map((value, idx) => (idx === index ? null : value));
       setShowDropdown(newShowDropdown);
       setSelectedCollege(newSelectedCollege);
   };

   const handleSearchChange = (index, query) => {
       const newSearchQuery = searchQuery.map((value, idx) => (idx === index ? query : value));
       setSearchQuery(newSearchQuery);
   };

   const filteredColleges = (index) => {
       return colleges.filter(college =>
           college.name.toLowerCase().includes(searchQuery[index].toLowerCase())
       );
   };

   const handleCompareNow = () => {
      const selectedColleges = selectedCollege.filter(college => college);
      
      if (selectedColleges.length < 2) {
          alert('Please select at least two colleges for comparison.');
          return;
      }

      const selectedUids = selectedColleges.map(college => college.uid);
      const collegeNames = selectedColleges.map(college => college.name).join('-vs-');

      const data = {
          university_ids: selectedUids
      };

      const requestOptions = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
      };

      fetch('https://webcrmapi.nayeedisha.in/api/compare-universities', requestOptions)
          .then(response => response.json())
          .then(result => {
              console.log('API Response:', result);

              // Save the comparison data to local storage
              localStorage.setItem('comparisonData', JSON.stringify(result));

              // Set the comparison data in context
              setComparisonData(result);

              // Construct the URL based on college names
              const urlSlug = collegeNames.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '');
              history.push(`/compare/${urlSlug}`);
          })
          .catch(error => {
              console.error('Error comparing universities:', error);
          });
  };
 
// close
  
   useEffect(() => {
      fetch('https://webcrmapi.nayeedisha.in/api/blog-details')

         .then((response) => response.json())
         .then((data) => {
            // Assuming 'results' contains the array of universities
            setBlogdetails(data);
            setIsLoading(false);
         
         })
         .catch((error) => {
            setIsLoading(false);
            console.error('Error fetching data:', error);
         });
   }, []);
   useEffect(() => {
      const fetchData = async () => {
         try {
            setIsLoading(true);

            const universityResponse = await fetch(apiUrl + 'university-details');
            const universityData = await universityResponse.json();
            setUniversities(universityData);
           

            const newsResponse = await fetch(apiUrl + 'news-details');
            const newsData = await newsResponse.json();
            setNewsdetails(newsData);
            

            const testimonialResponse = await fetch(apiUrl + 'testimonial-details');
            const testimonialData = await testimonialResponse.json();
            setTestimonial(testimonialData);
            

            const examsResponse = await fetch(apiUrl + 'exam-details');
            const examsData = await examsResponse.json();
            setExams(examsData);
            




            setIsLoading(false);
         } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data:', error);
         }
      };

      fetchData();
   }, []);

   const redirectHandler = () => router.push(redUrl);


   return (
      <Fragment>

         <div className="application">
            <Helmet>
               <meta charSet="utf-8" />
               <title>Top Colleges, Universities in India and Abroad : NayeeDisha </title>
               <meta name="description" content="Explore top colleges and universities in India and abroad with NayeeDisha. Expert guidance for admissions and career advice. Contact 8860031900 for assistance!" />
               <meta name="keywords"
                  content="Top Colleges in India and abroad, Abroad  education Consultants, MBBS Abroad Consultancy, Best Abroad Consultancy in India, Best university for MBBS Abroad, Study MBBS 2024-25" />
               <link rel="canonical" href="https://nayeedisha.in" />
            </Helmet>

         </div>
       
         {/* SECTION1 */}
         <div className="banner-section-copy ">
            <div className="container">

               <div className="row pt-5  pb-5 ">
                  <div className="col-md-5 " >
                     <div id="fonter">
                        <h5>Study Medicine Abroad</h5>
                        <p>At low Cost in <span className="cpr-2">World Ranked Universities! </span></p>
                        <p id="cpr-3"> Honest Counselling &nbsp;
                           <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none" className="m-">
                              <circle cx="4" cy="4" r="4" fill="#FFAA01" />

                           </svg> &nbsp;
                           University Selection &nbsp;
                           <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                              <circle cx="4" cy="4" r="4" fill="#FFAA01" />
                           </svg> &nbsp;
                           Admission
                        </p>
                     </div>
                     <div className="mt-4 d-flex justify-content-between flexyr">
                        <div>
                           <img src={bi1} width="" height="" alt="NayeeDisha" />
                           <p>India</p>
                        </div>
                        <div>
                           <img src={bi2} width="" height="" alt="NayeeDisha" />
                           <p>Russia</p>
                        </div>
                        <div>
                           <img src={bi3} width="" height="" alt="NayeeDisha" />
                           <p>Kyrgyzstan</p>
                        </div>
                        <div>
                           <img src={bi4} width="" height="" alt="NayeeDisha" />
                           <p>China</p>
                        </div>
                     </div>
                     <div className="mt-4 d-flex justify-content-between flexyr">
                        <div>
                           <img src={bi5} width="" height="" alt="NayeeDisha" />
                           <p>Kazakhstan</p>
                        </div>
                        <div>
                           <img src={bi6} width="" height="" alt="NayeeDisha" />
                           <p>Bangladesh</p>
                        </div>
                        <div>
                           <img src={bi7} width="" height="" alt="NayeeDisha" />
                           <p>Philippines</p>
                        </div>
                        <div>
                           <img src={bi8} width="" height="" alt="NayeeDisha" />
                           <p>Nepal</p>
                        </div>
                     </div>
                     <div className="text-center mt-4">
                        <button type="button" className="btn btn-primary px-4 p-3 wave_talk" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnrs1'>
                           Talk to our Counsellor &nbsp;&nbsp;&nbsp;</button>
                     </div>
                  </div>

                  <div className="col-md-4 offset-md-3">
                     <div className="banner-copy-form p-4 mt-2">
                        <h5>
                           Search Your University
                        </h5>
                        <p>Free forever. No payment needed.</p>
                        <label>Select Country*</label>
                        <select class="form-select mt-2 p-2" aria-label="Default select example" onChange={(e) => handleSubcourse(e.target.value)}>
                           <option >Select Country</option>
                           {DropDownCoursesData.map(x => <option key={x.value} value={x.value}>{x.name}</option>)}

                        </select>

                        <label className="mt-3">Select Course*</label>
                        <select class="form-select mt-2 p-2" aria-label="Default select example" onChange={(e) => handleSpecial(e.target.value)}>
                           <option >Select Course</option>
                           {subCourse.map(x => <option key={x.value} value={x.value}>{x.name}</option>)}
                        </select>
                        <label className="mt-3">Select College*</label>
                        <select class="form-select mt-2 p-2 mb-2" aria-label="Default select example" onChange={(e) => setUrl(e.target.value)}>
                           <option >Select College </option>
                           {special.map(x => <option key={x.value} value={x.url}>{x.name}</option>)}
                        </select>
                        <div className="cp-but mt-4">
                           <button type="button" class="btn btn-primary" onClick={redirectHandler}>Find Now</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* SECTION2 */}
         <div className="section2 pt-5 pb-4">
            <div className="container mobilesection-hide">
               <div className="d-flex justify-content-around boxess">
                  <div class="box1 d-flex col-lg-3 col-md-6 col-sm-12 col-3">
                     <div className="imr">
                        <img src={stu} alt="NayeeDisha" />
                     </div>
                     &nbsp;&nbsp;&nbsp;
                     <div>
                        <h5>50+ Research</h5>
                        <p>Staff</p>
                     </div>
                  </div>
                  <div class="box1 d-flex col-lg-3 col-md-6 col-sm-12 col-3">
                     <div className="imr">
                        <img src={worlwide} alt="NayeeDisha" />
                     </div>
                     &nbsp;&nbsp;&nbsp;
                     <div>
                        <h5>2000+ Enrolled</h5>
                        <p>Students</p>
                     </div>
                  </div>
                  <div class="box1 d-flex col-lg-3 col-md-6 col-sm-12 col-3">
                     <div className="imrun">
                        <img src={consulting} alt="NayeeDisha" />
                     </div>
                     &nbsp;&nbsp;&nbsp;
                     <div>
                        <h5>13+ years in</h5>
                        <p>Consulting</p>
                     </div>
                  </div>
                  <div class="box1 d-flex col-lg-3 col-md-6 col-sm-12 col-3">
                     <div className="imr">
                        <img src={countries} alt="NayeeDisha" />
                     </div>
                     &nbsp;&nbsp;&nbsp;
                     <div>
                        <h5>15+ Countries</h5>
                        <p className="p-head"> Served </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* END SECTION2 */}
         {/* mobile section */}
         {/* designing for mobile section */}
         <div className="container ">
            <div className="row sections2" >
               <div className="col-md-3 col-4 mt-3">
                  <div className="card myc1" style={{ width: '100%' }}>
                     <div className="card-body">
                        <div class=" imprs text-center">
                           <div className="imrs">
                              <img src={student2} alt="NayeeDisha" />
                           </div>
                           <div>
                              <h5>50+ Research</h5>
                              <p className="p-0 m-0 p-head">Staff</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-md-3 col-4 mt-3">
                  <div className="card myc1" style={{ width: '100%' }}>
                     <div className="card-body">
                        <div class=" imprs text-center">
                           <div className="imrs">
                              <img src={worldwide2} alt="NayeeDisha" />
                           </div>
                           <div>
                              <h5>20K+ Enrolled</h5>
                              <p className="p-0 m-0 p-head">Students</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-md-3 col-4 mt-3">
                  <div className="card myc1" style={{ width: '100%' }}>
                     <div className="card-body">
                        <div class=" imprs text-center">
                           <div className="imrs">
                              <img src={consulting2} alt="NayeeDisha" />
                           </div>
                           <div>
                              <h5>13+ years in</h5>
                              <p className="p-0 m-0 p-head">Consulting</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-md-3 col-3 mt-3 mob-hide">
                  <div className="card myc1" style={{ width: '100%' }}>
                     <div className="card-body">
                        <div class=" imprs text-center">
                           <div className="imrs">
                              <img src={countries2} alt="NayeeDisha" />
                           </div>
                           <div>
                              <h5>15+ Countries</h5>
                              <p className="p-0 m-0 p-head"> Served </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* end mobile section */}
         
         {/* section2-copy */}
         <div className="container">
            <div className="row">
               <div className="col-md-12 col-lg-4 mt-5 ">
                 <a href="/study-abroad">
                 
                  <div className="img-1 p-5">
                     <h5>MBBS <br></br> ABROAD</h5>
                     <p>in top university</p>
                  </div>
                  </a>
                  <div className="crd-copy-1 pt-3" >
                     <p>We At NayeeDisha offers MBBS abroad to top-notch universities with A to Z preparation through a proficient counsellors team. We approach 15+ counties & holding experience more than 13+ years of.
                        {/* Medical universities are globally recognized, and many of them are listed in international rankings. */}
                     </p>

                  </div>
               </div>
               <div className="col-md-12 col-lg-4 mt-5  ">
               <a href="/mbbs-india"><div className="img-2 p-5">
                     <h5>MBBS <br></br> INDIA</h5>
                     <p>in top university</p>
                  </div>
                  </a>
                  <div className="crd-copy-1 pt-3" >
                     <p>Get admission in recognized MBBS Universities or colleges at a very budget price . We cater to more than 100+ colleges for MBBS in any location in India.
                        {/* with diverse patient populations, shaping competent medical professionals. */}
                     </p>

                  </div>
               </div>
               <div className="col-md-12 col-lg-4 mt-5  ">
                  <div className="img-3 p-5">
                     <h5>ENGINEERING<br></br> INDIA</h5>
                     <p>in top university</p>
                  </div>
                  <div className="crd-copy-1 pt-3" >
                     <p>Become a future leader after completing the ENGINEERING program. We help to reserve a seat in your dream or desired college/university as well as provide excellent counselling.
                     </p>

                  </div>
               </div>
            </div>
         </div>
         <div className="container">
            <div className="row ">
               <div className="my-5">


                  {isLoading ? (
                     <div className="row">
                     {skeletonArray.map((_, index) => (
                        <div className="col-md-3" key={index}>
                           <Skeleton height={200} />
                           <div className="mt-2">
                              <Skeleton height={40} width="80%" />
                           </div>
                           <div className="mt-2">
                              <Skeleton height={20} width="60%" />
                           </div>
                           <div className="mt-2">
                              <Skeleton height={30} width="40%" />
                           </div>
                        </div>
                     ))}
                        </div>
                  ) : (

                     <div className='row position-stickey'>
                        <OwlCarousel className="owl-theme" {...options} >
                           {universities.map((data) => {
                              return (
                                 <Link to={`/university/${data.slug}`}>
                                 <div className="items">
                                    <div className="card" id="icons-crd" style={{ width: '100%' }}>

                                       <img src={data.cover_image} class="card-img-top" alt="NayeeDisha" />

                                       <div className="card-body iconerss-card">
                                          <img src={data.logo} class="" alt="NayeeDisha" />
                                         
                                          <Link to={`/university/${data.slug}`}>
                                             <h5 className="mt-1 text-transform">{data.name}</h5>
                                          </Link>
                                          <div>

                                             <div className=' pt-2'>
                                                <p className='para1' style={{ color: '#000' }}>Establish Year-<span className='ugc' style={{ color: '#000' }}>  {data.estb ? data.estb : 'Not found'} </span></p>



                                                {/* <p className='para1'>Total Budget -<span className='ugc'> {data.toatal_budget ? data.toatal_budget : 'Not found'} </span></p> */}
                                                <p className='para1'>Country-<span className='ugc' style={{ color: '#000' }}> {data.country ? data.country : 'Not found'} </span></p>
                                             </div>
                                             <div className='d-flex justify-content-between mt-3'>
                                                <Button type="button" className="btn btn-primary px-4 p-2 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnr1'>
                                                   Apply Now &nbsp;&nbsp;&nbsp;
                                                   <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.78009 4.45975C5.9209 4.6006 6 4.79161 6 4.99077C6 5.18993 5.9209 5.38094 5.78009 5.52179L1.53118 9.7707C1.46189 9.84244 1.37902 9.89966 1.28738 9.93902C1.19574 9.97839 1.09719 9.99911 0.997458 9.99997C0.897728 10.0008 0.798825 9.98183 0.706519 9.94407C0.614213 9.9063 0.530352 9.85053 0.45983 9.78001C0.389308 9.70949 0.333538 9.62563 0.295773 9.53332C0.258007 9.44102 0.239003 9.34211 0.23987 9.24238C0.240736 9.14266 0.261456 9.0441 0.30082 8.95246C0.340184 8.86083 0.397403 8.77795 0.46914 8.70866L4.18703 4.99077L0.469139 1.27288C0.332322 1.13122 0.256617 0.941496 0.258328 0.744563C0.260039 0.54763 0.33903 0.359248 0.478288 0.219989C0.617547 0.0807309 0.805929 0.001739 1.00286 2.80934e-05C1.1998 -0.00168282 1.38952 0.0740227 1.53118 0.210839L5.78009 4.45975Z" fill="white" />
                                                   </svg>
                                                </Button>
                                             
                                                <a href={`/university/${data.slug}`}>
                                                   
                                                   <Button className="btn" variant="outline-success">Read More</Button>
                                                </a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 </Link>
                              )
                           })
                           }
                        </OwlCarousel>
                        <div className="text-center">

                        <a href="/study-abroad"  className="text-white"><button className="btn btn-warning viewallbutton wave_talk text-white">  View All Universities </button></a>
                        </div>
                                        
                     </div>

                  )}

               </div>
            </div>
         </div>
         {/* for mobile */}
         <CompareCollege  />
         {/* <section className="compare_colleges mt-2 mb-2">
            <div className="container mt-4">
                <div className="text-center mt-4">
                    <h3></h3>
                    <h3 className="text-center" id="heads">
                        <span id="explore">Compare </span> Colleges
                    </h3>
                    <p className="text-center" id="my-p">
                        Explore your preferred streams to learn about the relevant colleges, exams, and more!
                    </p>
                </div>
                <div className="row mt-4">
                    {selectedCollege.map((college, index) => (
                        <div className="col-md-4 mt-2" key={index}>
                            <div className="compare ">
                                {college ? (
                                    <div className="card" id="icons-crd" style={{ width: '100%' }}>
                                         <button className="compare_closebutton" onClick={() => handleRemoveCollege(index)}>
                                            <FaTimes />
                                        </button>
                                        <div
                                            className="university-section "
                                            style={{
                                                width: '100%',
                                                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${college.cover_image})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: '100% 186%',
                                                height: '100px',
                                                backgroundPosition: 'center'
                                            }}
                                        >

                                        </div>
                                        <div className="card-body comparetext">
                                            <img src={college.logo} className="" alt="NayeeDisha" />
                                            
                                                <h5 className="mt-2 f-16 text-transform">{college.name}</h5>
                                           
                                            <div>
                                                <div className="pt-1">
                                               
                                                    <p className="para1" style={{ color: '#000' }}>
                                                        Country -
                                                        <span className="ugc" style={{ color: '#000' }}>
                                                            {college.country ? college.country : 'Not found'}
                                                        </span>
                                                    </p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                      
                                    </div>
                                ) : (
                                    <>
                                    <div className="text-center">

                                    
                                        <GoPlusCircle className="plusicon" onClick={() => handleAddCollegeClick(index)} />
                                        <p onClick={() => handleAddCollegeClick(index)}>Add College</p>
                                        {showDropdown[index] && (
                                            <div className="custom-dropdown">
                                                <input
                                                    type="text"
                                                    value={searchQuery[index]}
                                                    onChange={(e) => handleSearchChange(index, e.target.value)}
                                                    placeholder="Search college by name"
                                                    className="dropdown-input input_compair"
                                                    disabled={index > 0 && !selectedCollege[index - 1]}
                                                />
                                                <div className="dropdown-list compare_dropdown">
                                                    {filteredColleges(index).map(college => (
                                                        <div
                                                            key={college.uid}
                                                            className="dropdown-item compare_itemlist"
                                                            onClick={() => handleCollegeChange(index, college)}
                                                        >
                                                            {college.name}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <br />
                <div className="text-center mt-2">
                    <button
                        type="button"
                        className="btn btn-primary mt-3"
                        disabled={!selectedCollege[0]}
                        onClick={handleCompareNow}
                    >
                        Compare Now <IoGitCompareOutline />
                    </button>
                </div>
           

 </div>
        </section> */}
    
         {/* end mobile */}
         {/*  */}
         {/* copy-section-another */}
         <div className="container mb-5">
            <div className="row copyr">
               <div className="col-md-6 mt-5 pt-5">
                  <div className="mt-5 respond">
                     <h5 >NAYEE DISHA</h5>
                     <h5 id="copyr2">Scholarship and Talent Hunt</h5>
                     <p>The NayeeDisha has crafted a program of scholarship and talent hunt where we provide the right identity as well as reward to the students for their desired career domains. We build a Launchpad where students can achieve their dreams. Through the NayeeDisha, we generate a platform to connect & engage with universities across the globe and create opportunities to win scholarships. </p>
                     <p>Our main motive is to provide the right path to our students after our deep research and best approaches. We admire and support professional development throughout the globe and measure the skills, knowledge, and performance.</p>
                  </div>
                  <div className=" mt-4 d-none">
                     <button type="button" className="btn btn-primary px-4 p-3 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='btnr1'>
                        Register Now &nbsp;&nbsp;&nbsp;</button>&nbsp;&nbsp;
                     <button type="button" className="btn px-4 p-3 " data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo" id='logr'>
                        Login Now &nbsp;&nbsp;&nbsp;</button>&nbsp;&nbsp;
                  </div>
               </div>
               <div className="col-md-6 mt-5">
                  <img src={copyrimg} alt="NayeeDisha" />
               </div>
            </div>
         </div>

         <div className="container section3 pt-5">
            <div className="row">
               <h3>Counselling</h3>
               <p className="text-center">We have a highly qualified and experienced team that can assist you in just one call. We are ready to provide the best possible approaches to more than 300+ universities for MBBS or other courses. and we connect to 15+ nations across the globe. We do not only find the universities or colleges for the course while we provide the best counselling program from beginning to end where you can leverage various things. Such as:
               </p>
               <div className="col-md-5 mt-5">
                  <div className="card texter1">
                     <div className="card-body ">
                        <h6 className=""> <img src={student} alt="NayeeDisha" />    &nbsp;Get Free Counselling Online/Offline.  </h6>
                        <p>Personalized Video Counselling from Curated Experts on Exams, Courses, Colleges and Career selection on our App</p>
                        {/* 
                  <button type="button" class="btn btn-primary st-but p-2">
                     Start Now  &nbsp;
                     <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.54025 4.45975C5.68106 4.6006 5.76016 4.79161 5.76016 4.99077C5.76016 5.18993 5.68106 5.38094 5.54025 5.52179L1.29134 9.7707C1.22206 9.84244 1.13918 9.89966 1.04754 9.93902C0.955907 9.97839 0.857349 9.99911 0.75762 9.99997C0.657891 10.0008 0.558988 9.98183 0.466681 9.94407C0.374376 9.9063 0.290514 9.85053 0.219993 9.78001C0.149471 9.70949 0.0937004 9.62563 0.0559349 9.53332C0.0181694 9.44102 -0.000834523 9.34211 3.23587e-05 9.24238C0.000898763 9.14266 0.0216188 9.0441 0.0609826 8.95246C0.100346 8.86083 0.157565 8.77795 0.229302 8.70866L3.94719 4.99077L0.229302 1.27288C0.0924847 1.13122 0.0167791 0.941496 0.0184905 0.744563C0.0202018 0.54763 0.0991927 0.359248 0.238451 0.219989C0.377709 0.0807309 0.566092 0.001739 0.763025 2.80934e-05C0.959958 -0.00168282 1.14968 0.0740227 1.29134 0.210839L5.54025 4.45975Z" fill="white" />
                     </svg>
                  </button>
                  */}
                     </div>
                  </div>
                  <div className="card texter1 mt-4">
                     <div className="card-body ">
                        <h6 className=""> <img src={student} alt="NayeeDisha" />  &nbsp;QnA </h6>
                        <p>Personalized Video Counselling from Curated Experts on Exams, Courses, Colleges and Career selection on our App</p>
                        {/* 
                  <button type="button" class="btn btn-primary st-but p-2">
                     Start Now  &nbsp;
                     <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.54025 4.45975C5.68106 4.6006 5.76016 4.79161 5.76016 4.99077C5.76016 5.18993 5.68106 5.38094 5.54025 5.52179L1.29134 9.7707C1.22206 9.84244 1.13918 9.89966 1.04754 9.93902C0.955907 9.97839 0.857349 9.99911 0.75762 9.99997C0.657891 10.0008 0.558988 9.98183 0.466681 9.94407C0.374376 9.9063 0.290514 9.85053 0.219993 9.78001C0.149471 9.70949 0.0937004 9.62563 0.0559349 9.53332C0.0181694 9.44102 -0.000834523 9.34211 3.23587e-05 9.24238C0.000898763 9.14266 0.0216188 9.0441 0.0609826 8.95246C0.100346 8.86083 0.157565 8.77795 0.229302 8.70866L3.94719 4.99077L0.229302 1.27288C0.0924847 1.13122 0.0167791 0.941496 0.0184905 0.744563C0.0202018 0.54763 0.0991927 0.359248 0.238451 0.219989C0.377709 0.0807309 0.566092 0.001739 0.763025 2.80934e-05C0.959958 -0.00168282 1.14968 0.0740227 1.29134 0.210839L5.54025 4.45975Z" fill="white" />
                     </svg>
                  </button>
                  &nbsp;&nbsp; */}
                        <button type="button" class="btn st-buts p-2">
                           QnA
                           <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.30042 4.45975C6.44122 4.6006 6.52032 4.79161 6.52032 4.99077C6.52032 5.18993 6.44122 5.38094 6.30042 5.52179L2.05151 9.7707C1.98222 9.84244 1.89934 9.89966 1.8077 9.93902C1.71607 9.97839 1.61751 9.99911 1.51778 9.99997C1.41805 10.0008 1.31915 9.98183 1.22684 9.94407C1.13454 9.9063 1.05068 9.85053 0.980155 9.78001C0.909633 9.70949 0.853863 9.62563 0.816097 9.53332C0.778332 9.44102 0.759328 9.34211 0.760195 9.24238C0.761061 9.14266 0.781781 9.0441 0.821145 8.95246C0.860509 8.86083 0.917728 8.77795 0.989465 8.70866L4.70736 4.99077L0.989464 1.27288C0.852647 1.13122 0.776941 0.941496 0.778653 0.744563C0.780364 0.54763 0.859355 0.359248 0.998613 0.219989C1.13787 0.0807309 1.32625 0.001739 1.52319 2.80934e-05C1.72012 -0.00168282 1.90985 0.0740227 2.0515 0.210839L6.30042 4.45975Z" fill="#2D69F0" />
                           </svg>
                        </button>
                     </div>
                  </div>
               </div>
               <div className="col-md-6 offset-md-1 texter-pic mt-4">
                  <img src={counselling} alt="NayeeDisha" />
               </div>
            </div>
         </div>

         
         
         {/* <!-- SECTION4--> */}
         <div className="section4 pb-5 mt-5">
            <div className="container pt-5 pb-5">
               <div className="row">
                  <h3>Predictor</h3>
                  <p className="text-center">We predict your College admission chances and Ranks based on years of historical data<br></br> and advanced Analytics to help you plan in advance</p>
                  <div className="col-md-7 mt-3">
                     <div className="card" id="crd" style={{ width: '100%' }}>
                        <div className="card-body">
                           <h5><img src={studenter} alt="NayeeDisha" /> College Predictors</h5>
                           <p>Know your College Admission chances at the course-level based on domicile, caste, gender etc</p>
                           <div>
                              <button type="button" class="btn btn-primary mt-3">
                                 JEE Main College Predictor
                                 &nbsp;
                                 <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.54025 4.45975C5.68106 4.6006 5.76016 4.79161 5.76016 4.99077C5.76016 5.18993 5.68106 5.38094 5.54025 5.52179L1.29134 9.7707C1.22206 9.84244 1.13918 9.89966 1.04754 9.93902C0.955907 9.97839 0.857349 9.99911 0.75762 9.99997C0.657891 10.0008 0.558988 9.98183 0.466681 9.94407C0.374376 9.9063 0.290514 9.85053 0.219993 9.78001C0.149471 9.70949 0.0937004 9.62563 0.0559349 9.53332C0.0181694 9.44102 -0.000834523 9.34211 3.23587e-05 9.24238C0.000898763 9.14266 0.0216188 9.0441 0.0609826 8.95246C0.100346 8.86083 0.157565 8.77795 0.229302 8.70866L3.94719 4.99077L0.229302 1.27288C0.0924847 1.13122 0.0167791 0.941496 0.0184905 0.744563C0.0202018 0.54763 0.0991927 0.359248 0.238451 0.219989C0.377709 0.0807309 0.566092 0.001739 0.763025 2.80934e-05C0.959958 -0.00168282 1.14968 0.0740227 1.29134 0.210839L5.54025 4.45975Z" fill="white" />
                                 </svg>
                              </button>
                              &nbsp; &nbsp;
                              {/* 
                     <button type="button" class="btn btn-primary mt-3">
                        GATE College Predictor with PSU Chances
                        &nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                           <path fill-rule="evenodd" clip-rule="evenodd" d="M5.54025 4.45975C5.68106 4.6006 5.76016 4.79161 5.76016 4.99077C5.76016 5.18993 5.68106 5.38094 5.54025 5.52179L1.29134 9.7707C1.22206 9.84244 1.13918 9.89966 1.04754 9.93902C0.955907 9.97839 0.857349 9.99911 0.75762 9.99997C0.657891 10.0008 0.558988 9.98183 0.466681 9.94407C0.374376 9.9063 0.290514 9.85053 0.219993 9.78001C0.149471 9.70949 0.0937004 9.62563 0.0559349 9.53332C0.0181694 9.44102 -0.000834523 9.34211 3.23587e-05 9.24238C0.000898763 9.14266 0.0216188 9.0441 0.0609826 8.95246C0.100346 8.86083 0.157565 8.77795 0.229302 8.70866L3.94719 4.99077L0.229302 1.27288C0.0924847 1.13122 0.0167791 0.941496 0.0184905 0.744563C0.0202018 0.54763 0.0991927 0.359248 0.238451 0.219989C0.377709 0.0807309 0.566092 0.001739 0.763025 2.80934e-05C0.959958 -0.00168282 1.14968 0.0740227 1.29134 0.210839L5.54025 4.45975Z" fill="white" />
                        </svg>
                     </button>
                     &nbsp; &nbsp; */}
                              <button type="button" class="btn btn-primary mt-3">
                                 NEET College Predictor
                                 &nbsp;
                                 <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.54025 4.45975C5.68106 4.6006 5.76016 4.79161 5.76016 4.99077C5.76016 5.18993 5.68106 5.38094 5.54025 5.52179L1.29134 9.7707C1.22206 9.84244 1.13918 9.89966 1.04754 9.93902C0.955907 9.97839 0.857349 9.99911 0.75762 9.99997C0.657891 10.0008 0.558988 9.98183 0.466681 9.94407C0.374376 9.9063 0.290514 9.85053 0.219993 9.78001C0.149471 9.70949 0.0937004 9.62563 0.0559349 9.53332C0.0181694 9.44102 -0.000834523 9.34211 3.23587e-05 9.24238C0.000898763 9.14266 0.0216188 9.0441 0.0609826 8.95246C0.100346 8.86083 0.157565 8.77795 0.229302 8.70866L3.94719 4.99077L0.229302 1.27288C0.0924847 1.13122 0.0167791 0.941496 0.0184905 0.744563C0.0202018 0.54763 0.0991927 0.359248 0.238451 0.219989C0.377709 0.0807309 0.566092 0.001739 0.763025 2.80934e-05C0.959958 -0.00168282 1.14968 0.0740227 1.29134 0.210839L5.54025 4.45975Z" fill="white" />
                                 </svg>
                              </button>
                              &nbsp; &nbsp;
                              <button type="button" class="btn btn-primary mt-3">
                                 NEET PG College Predictor
                                 &nbsp;
                                 <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.54025 4.45975C5.68106 4.6006 5.76016 4.79161 5.76016 4.99077C5.76016 5.18993 5.68106 5.38094 5.54025 5.52179L1.29134 9.7707C1.22206 9.84244 1.13918 9.89966 1.04754 9.93902C0.955907 9.97839 0.857349 9.99911 0.75762 9.99997C0.657891 10.0008 0.558988 9.98183 0.466681 9.94407C0.374376 9.9063 0.290514 9.85053 0.219993 9.78001C0.149471 9.70949 0.0937004 9.62563 0.0559349 9.53332C0.0181694 9.44102 -0.000834523 9.34211 3.23587e-05 9.24238C0.000898763 9.14266 0.0216188 9.0441 0.0609826 8.95246C0.100346 8.86083 0.157565 8.77795 0.229302 8.70866L3.94719 4.99077L0.229302 1.27288C0.0924847 1.13122 0.0167791 0.941496 0.0184905 0.744563C0.0202018 0.54763 0.0991927 0.359248 0.238451 0.219989C0.377709 0.0807309 0.566092 0.001739 0.763025 2.80934e-05C0.959958 -0.00168282 1.14968 0.0740227 1.29134 0.210839L5.54025 4.45975Z" fill="white" />
                                 </svg>
                              </button>
                              &nbsp; &nbsp;
                              <button type="button" class="btn btn-primary mt-3">
                                 MHT CET College Predictor
                                 &nbsp;
                                 <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.54025 4.45975C5.68106 4.6006 5.76016 4.79161 5.76016 4.99077C5.76016 5.18993 5.68106 5.38094 5.54025 5.52179L1.29134 9.7707C1.22206 9.84244 1.13918 9.89966 1.04754 9.93902C0.955907 9.97839 0.857349 9.99911 0.75762 9.99997C0.657891 10.0008 0.558988 9.98183 0.466681 9.94407C0.374376 9.9063 0.290514 9.85053 0.219993 9.78001C0.149471 9.70949 0.0937004 9.62563 0.0559349 9.53332C0.0181694 9.44102 -0.000834523 9.34211 3.23587e-05 9.24238C0.000898763 9.14266 0.0216188 9.0441 0.0609826 8.95246C0.100346 8.86083 0.157565 8.77795 0.229302 8.70866L3.94719 4.99077L0.229302 1.27288C0.0924847 1.13122 0.0167791 0.941496 0.0184905 0.744563C0.0202018 0.54763 0.0991927 0.359248 0.238451 0.219989C0.377709 0.0807309 0.566092 0.001739 0.763025 2.80934e-05C0.959958 -0.00168282 1.14968 0.0740227 1.29134 0.210839L5.54025 4.45975Z" fill="white" />
                                 </svg>
                              </button>
                              &nbsp; &nbsp;
                              {/* 
                     
                     */}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-md-5 mt-3">
                     <div class="card" id="crd" style={{ width: '100%' }}>
                        <div class="card-body">
                           <h5><img src={studenter} alt="NayeeDisha" /> Rank Predictors</h5>
                           <p>Predict your Rank before the actual results based on your performance in the exam and get in-depth insights</p>
                           <div>

                              <button type="button" class="btn btn-primary mt-3">
                                 NEET Rank Predictor
                                 &nbsp;
                                 <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.54025 4.45975C5.68106 4.6006 5.76016 4.79161 5.76016 4.99077C5.76016 5.18993 5.68106 5.38094 5.54025 5.52179L1.29134 9.7707C1.22206 9.84244 1.13918 9.89966 1.04754 9.93902C0.955907 9.97839 0.857349 9.99911 0.75762 9.99997C0.657891 10.0008 0.558988 9.98183 0.466681 9.94407C0.374376 9.9063 0.290514 9.85053 0.219993 9.78001C0.149471 9.70949 0.0937004 9.62563 0.0559349 9.53332C0.0181694 9.44102 -0.000834523 9.34211 3.23587e-05 9.24238C0.000898763 9.14266 0.0216188 9.0441 0.0609826 8.95246C0.100346 8.86083 0.157565 8.77795 0.229302 8.70866L3.94719 4.99077L0.229302 1.27288C0.0924847 1.13122 0.0167791 0.941496 0.0184905 0.744563C0.0202018 0.54763 0.0991927 0.359248 0.238451 0.219989C0.377709 0.0807309 0.566092 0.001739 0.763025 2.80934e-05C0.959958 -0.00168282 1.14968 0.0740227 1.29134 0.210839L5.54025 4.45975Z" fill="white" />
                                 </svg>
                              </button>
                              &nbsp; &nbsp;
                              <button type="button" class="btn btn-primary mt-3">
                                 NEET PG Rank Predictor
                                 &nbsp;
                                 <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.54025 4.45975C5.68106 4.6006 5.76016 4.79161 5.76016 4.99077C5.76016 5.18993 5.68106 5.38094 5.54025 5.52179L1.29134 9.7707C1.22206 9.84244 1.13918 9.89966 1.04754 9.93902C0.955907 9.97839 0.857349 9.99911 0.75762 9.99997C0.657891 10.0008 0.558988 9.98183 0.466681 9.94407C0.374376 9.9063 0.290514 9.85053 0.219993 9.78001C0.149471 9.70949 0.0937004 9.62563 0.0559349 9.53332C0.0181694 9.44102 -0.000834523 9.34211 3.23587e-05 9.24238C0.000898763 9.14266 0.0216188 9.0441 0.0609826 8.95246C0.100346 8.86083 0.157565 8.77795 0.229302 8.70866L3.94719 4.99077L0.229302 1.27288C0.0924847 1.13122 0.0167791 0.941496 0.0184905 0.744563C0.0202018 0.54763 0.0991927 0.359248 0.238451 0.219989C0.377709 0.0807309 0.566092 0.001739 0.763025 2.80934e-05C0.959958 -0.00168282 1.14968 0.0740227 1.29134 0.210839L5.54025 4.45975Z" fill="white" />
                                 </svg>
                              </button>
                              &nbsp; &nbsp;
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* END SECTION4 */}
  

    

         {/* SECTION 7 */}



            <div className="container pt-5 pb-5  ">

               <h3 className="text-center " id="heads"><span id="explore">Important </span>  Exam & Date</h3>
               <p className="text-center" id="my-p"> Explore your preferred streams to learn about the relevant colleges, exams and more!</p>
               {/* my coder */}
               {isLoading ? (
                  <div className='text-center'>
                    <MagnifyingGlass
                           visible={true}
                           height="80"
                           width="80"
                           ariaLabel="magnifying-glass-loading"
                           wrapperStyle={{}}
                           wrapperClass="magnifying-glass-wrapper"
                           glassColor="#c0efff"
                           color="#e15b64"
                        />
                  </div>
               ) : (


                  <div className='row'>

                     {exams.slice(0, 6).map((examsdata) => {
                        return (

                           <div className=" col-md-6 col-lg-4 mt-3">
                              <div class="card  tabber" style={{ width: '100%' }}>
                                 <div class="card-body  ">
                                    <div className="d-flex logers">
                                       {/* <div><img src={loger1} alt="NayeeDisha" /></div> */}
                                       &nbsp;&nbsp;
                                       <div className="mt-2">
                                          <h5 className="p-1 ">{examsdata.mode_of_exam}</h5>
                                          <p className="exam-text">{examsdata.exam_name ? examsdata.exam_name : 'Null'}</p>
                                       </div>
                                    </div>
                                    <div className="row next-pr mt-3">
                                       <div className="col-md-6">
                                          <p>Total Seats</p>
                                       </div>
                                       <div className="col-md-6 text-end nexter-hr">
                                          <h5>{examsdata.total_seats ? examsdata.total_seats : 'Null'}</h5>
                                       </div>
                                    </div>
                                    <div className="row next-pr">
                                       <div className="col-md-6">
                                          <p>Exam Date</p>
                                       </div>
                                       <div className="col-md-6 text-end nexter-hr">
                                          <h5>{examsdata.exam_start_date}</h5>
                                       </div>
                                    </div>
                                    <div className="row next-pr">
                                       <div className="col-md-6">
                                          <p>Exam Level</p>
                                       </div>
                                       <div className="col-md-6 text-end nexter-hr">
                                          <h5>{examsdata.exam_level}</h5>
                                       </div>
                                    </div>
                                    <div className="d-flex">
                                       <button type="button" class="btn st-butss p-2">
                                          Application Process &nbsp;
                                          <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                             <path fill-rule="evenodd" clip-rule="evenodd" d="M5.54025 4.45975C5.68106 4.6006 5.76016 4.79161 5.76016 4.99077C5.76016 5.18993 5.68106 5.38094 5.54025 5.52179L1.29134 9.7707C1.22206 9.84244 1.13918 9.89966 1.04754 9.93902C0.955907 9.97839 0.857349 9.99911 0.75762 9.99997C0.657891 10.0008 0.558988 9.98183 0.466681 9.94407C0.374376 9.9063 0.290514 9.85053 0.219993 9.78001C0.149471 9.70949 0.0937004 9.62563 0.0559349 9.53332C0.0181694 9.44102 -0.000834523 9.34211 3.23587e-05 9.24238C0.000898763 9.14266 0.0216188 9.0441 0.0609826 8.95246C0.100346 8.86083 0.157565 8.77795 0.229302 8.70866L3.94719 4.99077L0.229302 1.27288C0.0924847 1.13122 0.0167791 0.941496 0.0184905 0.744563C0.0202018 0.54763 0.0991927 0.359248 0.238451 0.219989C0.377709 0.0807309 0.566092 0.001739 0.763025 2.80934e-05C0.959958 -0.00168282 1.14968 0.0740227 1.29134 0.210839L5.54025 4.45975Z" fill="#222222" />
                                          </svg>
                                       </button>
                                       &nbsp;&nbsp;
                                       <Link to={`/exams-details/${examsdata.slug}`}>
                                          <button type="button" class="btn st-butss p-2">

                                             Exam info &nbsp;
                                             <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.54025 4.45975C5.68106 4.6006 5.76016 4.79161 5.76016 4.99077C5.76016 5.18993 5.68106 5.38094 5.54025 5.52179L1.29134 9.7707C1.22206 9.84244 1.13918 9.89966 1.04754 9.93902C0.955907 9.97839 0.857349 9.99911 0.75762 9.99997C0.657891 10.0008 0.558988 9.98183 0.466681 9.94407C0.374376 9.9063 0.290514 9.85053 0.219993 9.78001C0.149471 9.70949 0.0937004 9.62563 0.0559349 9.53332C0.0181694 9.44102 -0.000834523 9.34211 3.23587e-05 9.24238C0.000898763 9.14266 0.0216188 9.0441 0.0609826 8.95246C0.100346 8.86083 0.157565 8.77795 0.229302 8.70866L3.94719 4.99077L0.229302 1.27288C0.0924847 1.13122 0.0167791 0.941496 0.0184905 0.744563C0.0202018 0.54763 0.0991927 0.359248 0.238451 0.219989C0.377709 0.0807309 0.566092 0.001739 0.763025 2.80934e-05C0.959958 -0.00168282 1.14968 0.0740227 1.29134 0.210839L5.54025 4.45975Z" fill="#222222" />
                                             </svg>
                                          </button>
                                       </Link>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        )
                     })}

                     <div className="text-center mt-3">
                        <a href="exams"><button type="button" class="btn btn-warning viewallbutton wave_talk text-white">View All</button></a>
                     </div>


                  </div>

               )}
            </div>
       



         {/* END SECTION7 */}
         {/* <!-- SECTION5 --> */}
         {newsdetails.title ?
            <div className="section5">
               <div className="container pt-5 pb-5">
                  {isLoading ? (
                     <div className='text-center'>
                        <MagnifyingGlass
                           visible={true}
                           height="80"
                           width="80"
                           ariaLabel="magnifying-glass-loading"
                           wrapperStyle={{}}
                           wrapperClass="magnifying-glass-wrapper"
                           glassColor="#c0efff"
                           color="#e15b64"
                        />
                     </div>
                  ) : (
                     <div className="row">
                        <h3 className="text-center"><span className="latest">Latest</span> News & Stories</h3>
                        <p className="text-center">Explore your preferred streams to learn about the relevant colleges, exams and more!</p>
                        {newsdetails.slice(0, 4).map((newsdetails) => {
                           const truncatedContent = newsdetails.content?.split(' ').slice(0, 25).join(' ');
                           // Convert created_at to a Date object
                           const createdAtDate = new Date(newsdetails.created_at);

                           // Define months array
                           const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                           // Get day, month, and year
                           const day = createdAtDate.getDate();
                           const monthIndex = createdAtDate.getMonth();
                           const year = createdAtDate.getFullYear();

                           // Format the date string
                           const formattedDate = `${day} ${months[monthIndex]} ${year}`;
                           return (
                              <div className="col-md-6  mt-3">
                                 <div className="card" style={{ width: '100%' }}>
                                    <div className="card-body crdr1 ">
                                       <div className="row">
                                          <div className="col-md-12 col-lg-8 col-6 ">
                                             <h5 style={{ textTransform: 'capitalize' }}>{newsdetails.title ? newsdetails.title : 'Not found'}</h5>
                                             <p>{formattedDate}</p>

                                             <p className="next-para">  <div dangerouslySetInnerHTML={{ __html: truncatedContent || '' }} />

                                                <Link to={`/news-details/${newsdetails.slug}`}>  View More </Link></p>
                                          </div>
                                          <div className="col-md-12 col-lg-4 col-6">
                                             <div> <img src={newsdetails.thumbnail} alt={newsdetails.title} className="news_image" /></div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                              </div>
                           )
                        })
                        }
                     </div>
                  )}

                  <div className="text-center mt-3">
                     <a href="/news"><button type="button" class="btn btn-warning viewallbutton wave_talk text-white">View All</button></a>
                  </div>
               </div>

            </div>
            : null
         }

         {/* next slider */}

         <div className="section5 mt-4">
            <h3 className="text-center headingline">
               <span className="latest blog">Latest </span> Blogs
            </h3>

            <div className="container pt-5 pb-5">
               {isLoading ? (
                  <div className='text-center'>
                     <MagnifyingGlass
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="magnifying-glass-loading"
                        wrapperStyle={{}}
                        wrapperClass="magnifying-glass-wrapper"
                        glassColor="#c0efff"
                        color="#e15b64"
                     />
                  </div>
               ) : (
                  <div className="row">
                     {blogdetails.slice(0, 3).map((blogdetails) => {
                        const truncatedContent = blogdetails.content?.split(' ').slice(0, 25).join(' ');
                        // Convert created_at to a Date object
                        const createdAtDate = new Date(blogdetails.created_at);

                        // Define months array
                        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                        // Get day, month, and year
                        const day = createdAtDate.getDate();
                        const monthIndex = createdAtDate.getMonth();
                        const year = createdAtDate.getFullYear();

                        // Format the date string
                        const formattedDate = `${day} ${months[monthIndex]} ${year}`;
                        return (
                           <div className="col-md-4  mt-3">
                              <Link to={`/blogs/${blogdetails.slug}`}>
                                 <div className="card" style={{ width: '100%' }}>
                                    <img src={blogdetails.thumbnail} className="w-100 blog" />
                                    <div className="card-body crdr1 ">
                                       <div className="row">
                                          <div className="col-md-12 col-lg-12 col-12 ">
                                             <b style={{ textTransform: 'capitalize' }}>{blogdetails.title ? blogdetails.title : 'Not found'}</b>
                                             <div className="d-flex justify-content-between">
                                                <p className="py-2">{formattedDate}</p>
                                                <p className="py-2"><GrView />&nbsp;{blogdetails.views}</p>
                                             </div>
                                             {/* <p className="next-para">{truncatedContent}...      <Link to={`/blog-details/${blogdetails.slug}`}> View More       </Link></p> */}
                                             <div dangerouslySetInnerHTML={{ __html: truncatedContent || '' }} />..<Link to={`/blogs/${blogdetails.slug}`}> View More       </Link>
                                          </div>

                                       </div>
                                    </div>
                                 </div>
                              </Link>
                           </div>
                        )
                     })
                     }
                  </div>
               )}
               <div className="text-center mt-3">
                  <a href="/blogs"><button type="button" class="btn btn-warning viewallbutton wave_talk text-white">View All Latest Blogs <MdOutlineReadMore /></button></a>
               </div>
            </div>

         </div>

         <div className="mb-5 testersection">

            <div className="container">
               <h3 className="text-center"><span className="latest stydentsays"></span> Our Students have to say</h3>




               {isLoading ? (
                  <div className='text-center'>

                     <MagnifyingGlass
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="magnifying-glass-loading"
                        wrapperStyle={{}}
                        wrapperClass="magnifying-glass-wrapper"
                        glassColor="#c0efff"
                        color="#e15b64"
                     />
                  </div>
               ) : (

                  <div className='row mt-4'>
                     <OwlCarousel className="owl-theme" {...optionstest} >
                        {testimonial.map((testimonial) => {
                           return (

                              <div class="card testerbox mt-4 mb-4">
                                 <div class="card-body ">
                                    <div className="d-flex">
                                       <div><img src={woman} width="" height="" alt="NayeeDisha" /></div>
                                       &nbsp;&nbsp;&nbsp;
                                       <div className="mt-3">
                                          <h5>{testimonial.author}</h5>
                                      
                                       </div>
                                    </div>
                                    <p className="pb-4">
                                       {testimonial.content}
                                    </p>
                                 </div>
                              </div>

                           )
                        })
                        }
                     </OwlCarousel>
                  </div>

               )}

            </div>
         </div>






         {/*  */}

      </Fragment>
   );
};
export default Home;