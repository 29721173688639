export const DropDownCoursesData = [
  {
    value: "0",
    name: "Russia",
    subCourses: [
      {
        value: "0",
        name: "MBBS",
        specialization: [
          {
            value: "1",
            name: "MBBS In Bashkir State Medical University",
            url: "/mbbs-in-bashkir-state-medical-university",
          },
          {
            value: "2",
            name: "MBBS in Chuvash State Medical University Russia",
            url: "/mbbs-in-chuvash-state-medical-university-russia",
          },
          {
            value: "3",
            name: "MBBS in OMSK State Medical University Russia",
            url: "mbbs-in-omsk-state-medical-university-russia",
          },
          {
            value: "4",
            name: "MBBS in Crimea Federal University Russia",
            url: "/mbbs-in-crimea-federal-university-russia",
          },
          
        ],
      },
    ],
  },

  // Philippines
  {
    value: "1",
    name: "Philippines",
    subCourses: [
      {
        value: "0",
        name: "MBBS ",
        specialization: [
            {
              value: "1",
              name: "MBBS in Brokenshire College of Medicine Philippines",
              url: "/mbbs-in-brokenshire-college-of-medicine-philippinespg-courses/online-distance-mba/hr-management",
            },
            {
              value: "2",
              name: "MBBS in Our Lady of Fatima University Philippines",
              url: "/mbbs-in-our-lady-of-fatima-university-philippines",
            },
            {
                value: "3",
                name: "MBBS in University of Perpetual Help System Dalta Philippines",
                url: "/mbbs-in-university-of-perpetual-help-system-dalta-philippines",
              },
            

          ],
        },
    ],
  },
  // Kyrgyzystan
   {
    value: "2",
    name: "Kyrgyzystan",
    subCourses: [
      {
        value: "0",
        name: "MBBS",
        specialization: [
            {
              value: "1",
              name: "MBBS in OSH State Medical University Kyrgyzstan",
              url: "/mbbs-in-osh-state-medical-university-kyrgyzstan",
            },
            {
              value: "2",
              name: "MBBS in Jalalabad State University Kyrgyzstan",
              url: "/mbbs-in-jalalabad-state-university-kyrgyzstan",
            },
            // {
            //     value: "3",
            //     name: "MBBS in Kazakh National Medical University Kyrgyzstan",
            //     url: "/mbbs-in-kazakh-national-medical-university-kyrgyzstan",
            //   },
             


          ],
      },
     
    ],
  },
  // China
  {
    value: "3",
    name: "China",
    subCourses: [
      {
        value: "0",
        name: "MBBS",
        specialization: [
            {
              value: "1",
              name: "MBBS in Peking University Health Science Center China",
              url: "/mbbs-in-fudan-university-shanghai-medical-college-china",
            },
            {
              value: "2",
              name: "MBBS in Fudan University Shanghai Medical College China",
              url: "/mbbs-in-fudan-university-shanghai-medical-college-china",
            },
            {
                value: "3",
                name: "MBBS in Zhejiang University School of Medicine China",
                url: "/mbbs-in-zhejiang-university-school-of-medicine-china",
              },
              


          ],
      },
      
    ],
  },
  // Bangladesh
  {
    value: "4",
    name: "Bangladesh",
    subCourses: [
      {
        value: "0",
        name: "MBBS",
        specialization: [
            {
              value: "1",
              name: "MBBS In Dhaka National Medical College Bangladesh",
              url: "/mbbs-in-dhaka-national-medical-college-bangladesh",
            },
            {
              value: "2",
              name: "MBBS in Eastern Medical College Bangladesh",
              url: "/mbbs-in-eastern-medical-college-bangladesh",
            },
            {
                value: "3",
                name: "MBBS in Khwaja Yunus Ali Medical College Bangladesh",
                url: "/mbbs-in-khwaja-yunus ali-medical-college-bangladesh",
              },
              {
                value: "4",
                name: "MBBS in Holy Family Red Crescent Medical College Bangladesh",
                url: "/mbbs-in-holy-family-red-crescent-medical-college-bangladesh",
              },
              {
                value: "5",
                name: "MBBS in Community Based Medical College Bangladesh",
                url: "/mbbs-in-community-based-medical-college-bangladesh",
              },
             


          ],
      },
      // {
      //   value: "1",
      //   name: "Online & Distance MCA",
      //   specialization: [
      //     {
      //       value: "1",
      //       name: "Data Science",
      //       url: "/pg-courses/online-distance-mca/data-science",
      //     },
      //     {
      //       value: "2",
      //       name: "Cloud Computing",
      //       url: "/pg-courses/online-distance-mca/cloud-computing",
      //     },
      //     {
      //       value: "3",
      //       name: "Artificial Intelligence",
      //       url: "/pg-courses/online-distance-mca/artificial-intelligence",
      //     },
      //     {
      //       value: "4",
      //       name: "Game Development",
      //       url: "/pg-courses/online-distance-mca/game-development",
      //     },{
      //       value: "5",
      //       name: "Data Analytics",
      //       url: "/pg-courses/online-distance-mca/data-analytics",
      //     },
      //     {
      //       value: "6",
      //       name: "Computer Science And IT",
      //       url: "/pg-courses/online-distance-mca/computer-science-and-it",
      //     },
      //     {
      //       value: "7",
      //       name: "Reality and Virtual Reality",
      //       url: "/",
      //     },
      //     {
      //       value: "8",
      //       name: "Machine Learning",
      //       url: "/pg-courses/online-distance-mca/machine-learning",
      //     },
      //     {
      //       value: "9",
      //       name: "Block chain",
      //       url: "/pg-courses/online-distance-mca/blockchain",
      //     },{
      //       value: "10",
      //       name: "Data Science and BigData Analytics",
      //       url: "/pg-courses/online-distance-mca/data-science-and-big-data-analytics",
      //     },
      //     {
      //       value: "11",
      //       name: "ML and AI",
      //       url: "/pg-courses/online-distance-mca/ml-and-aI",
      //     },



 
      //   ],
      // },
      // {
      //   value: "2",
      //   name: "Online & Distance M.Com",
      //   specialization: [
      //     {
      //       value: "1",
      //       name: "Accounting and Finance",
      //       url: "/pg-courses/online-distance-mcom/account-finance",
      //     },
      //     {
      //       value: "2",
      //       name: "International Finance",
      //       url: "/pg-courses/online-distance-mcom/international-finance",
      //     },
      //     {
      //       value: "3",
      //       name: "Professional Accounting and Finance",
      //       url: "/pg-courses/online-distance-mcom/professional-accounting-finance",
      //     },
         
      //   ],
      // },
      // {
      //   value: "3",
      //   name: "Online & Distance MA",
      //   specialization: [
      //     {
      //       value: "1",
      //       name: "Economics",
      //       url: "/pg-courses/online-distance-ma/economics",
      //     },
      //     {
      //       value: "2",
      //       name: "Journalism and Mass Communication",
      //       url: "/pg-courses/online-distance-ma/journalism-mass-communication",
      //     },
      //     {
      //       value: "3",
      //       name: "English",
      //       url: "/pg-courses/online-distance-ma/english",
      //     },
      //     {
      //       value: "4",
      //       name: "Psychology",
      //       url: "/pg-courses/online-distance-ma/psychology",
      //     },


      //     {
      //       value: "5",
      //       name: "Public Policy and Administration",
      //       url: "/pg-courses/online-distance-ma/public-policy-administration",
      //     },
      //     {
      //       value: "6",
      //       name: "Liberal Arts",
      //       url: "/pg-courses/online-distance-ma/liberal-arts",
      //     },
      //     {
      //       value: "7",
      //       name: "Sociology",
      //       url: "/pg-courses/online-distance-ma/sociology",
      //     },
      //     {
      //       value: "8",
      //       name: "History",
      //       url: "/pg-courses/online-distance-ma/history",
      //     },
      //     {
      //       value: "9",
      //       name: "Political Science",
      //       url: "/pg-courses/online-distance-ma/political-science",
      //     },
      //   ],
      // },
      // {
      //   value: "4",
      //   name: "M tech for Working Professionals",
      //   specialization: [
      //     {
      //       value: "1",
      //       name: "Mechanical Production",
      //       url: "/ug-courses/online-distance-mtech/mechincal-engineering",
      //     },
      //     {
      //       value: "2",
      //       name: "Computer Science Engineering",
      //       url: "/ug-courses/online-distance-mtech/computer-science-engineering",
      //     },
      //     {
      //       value: "3",
      //       name: "Civil Engineering",
      //       url: "/ug-courses/online-distance-mtech/civil-engineering",
      //     },
      //     {
      //       value: "4",
      //       name: "Electrical Engineering",
      //       url: "/ug-courses/online-distance-mtech/electrical-engineering",
      //     },
      //   ],
      // },
      // {
      //   value: "5",
      //   name: "Online & Distance M.Sc",
      //   specialization: [
      //     {
      //       value: "1",
      //       name: "AI and Machine Learning",
      //       url: "/pg-courses/online-distance-msc/ai-machine-learning",
      //     },
      //     {
      //       value: "2",
      //       name: "Applied Finance",
      //       url: "/pg-courses/online-distance-msc/applied-finance",
      //     },
      //     {
      //       value: "3",
      //       name: "Data Science",
      //       url: "/pg-courses/online-distance-msc/data-science",
      //     },
      //     {
      //       value: "4",
      //       name: "Mathematics",
      //       url: "/pg-courses/online-distance-msc/mathematics",
      //     },
      //   ],
      // },
    ],
  },
  // Kazakhstan
  {
    value: "5",
    name: "Kazakhstan",
    subCourses: [
      {
        value: "0",
        name: "MBBS",
        specialization: [
            {
              value: "1",
              name: "MBBS in South Kazakhstan Medical Academy Kazakhstan",
              url: "/mbbs-in-south-kazakhstan-medical-academy-kazakhstan",
            },
            {
              value: "2",
              name: "MBBS in Kazakh National Medical University Kazakhstan",
              url: "/mbbs-in-kazakh-national-medical-university-kazakhstan",
            },
            {
                value: "3",
                name: "MBBS in Al Farabi Kazakh National University Kazakhstan Marketing Management",
                url: "/mbbs-in-ai-farabi-kazakh-national-university-kazakhstan",
              },
              {
                value: "4",
                name: "MBBS in International Medical School Kazakhstan",
                url: "/mbbs-in-international-medical-school-kazakhstan",
              },
              {
                value: "5",
                name: "MBBS in Kokshetau State University Kazakhstan",
                url: "/mbbs-in-kokshetau-state-university-kazakhstan",
              },
             
        ],
      },
    ],
  },
];
