import { React, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

const PrivacyPolicy = () => {
    const { slug } = useParams();
    
    return (
        <>
            {/* section1 */}

            <div className='abt-section-news pt-5 pb-5'>


                <div className='container'>

                    <h3 className="text-white text-center"> Privacy Policy</h3>

            
                    <div id='brdrs'></div>
             
                    {/* <p>It is a long established fact that a reader.</p> */}


                </div>


            </div>
            <div className='container abt-2 mt-5 mb-5'>

<h3>Privacy Policy for NayeeDisha PVT. LTD. </h3>
<p className='mb-1'>At Nayeedisha, accessible from <a href="https://nayeedisha.in" target="_blank">https://nayeedisha.in, </a>one of our main assets is the privacy of users or visitors. This Privacy Policy content or document contains various kinds of details collected by Nayeedisha and complete details about how we use our privacy policy. 
If anyone wants to ask any additional queries or request more details about the privacy policy so don’t hesitate to contact us at <a href='https://nayeedisha.in/contact-us' target="_blank">https://nayeedisha.in/contact-us</a> . 
</p>

<p className='mb-1'>We at NayeeDisha offer a privacy policy that will only apply to online activities and it will apply to all our website visitors associated with the information that they share between users or collect in Nayeedisha. in. The Nayeedisha privacy policy information doesn't cover offline information or any official source or other website/mobile app. Our privacy policy has been created only for the consent of our website or any information presented on our website. 
</p>

<b>Consent</b>
<p className='mb-1'>The privacy policy will also be applicable for the personal information that you ask through chat or another source of the website. 
</p>

<b>Information We Gathered: </b>
<p className='mb-1'>We always try to provide transparent and clear information or details against the questions, and also, we have the right to ask about your details such as name, contact number, email address, qualification, or other related things. 
If you visit through our Contact Us webpage, we can also eligible to ask about your name, phone number, attachments, email address, or other personal details. 
When signing up or registering our Nayeedisha account so you have to provide your contact details namely name, address, phone number, email address, company name, or other specific things. 
 
</p>



<b className='mt-1'>How We Use Your Information? </b>
<p>We use the collected information in many ways including: </p>
<ul className='mb-4'>

    <li>
        Operational or maintenance on our website. </li>
    <li className='mt-2'>Improve expansion or personalization on our website. </li>
    <li className='mt-2'>Service, features, functions, and products. </li>
    <li className='mt-2'>General communication directly or indirectly, through our partner, customer service, updates, website, marketing, or promotional purposes</li>
    <li className='mt-2'>Send you emails and fraud prevention.
    </li>


</ul>
<b className='mt-1'>Log Files: </b>
<p>Nayeedisha. in covers all the standards or procedures of the log files and these log files are effectively applicable by log when the visitor visits our website. All hosting companies or hosting service providers are part of the hosting service analytics. The information collected by the log files includes browser type, internet service provider (ISP), date and time stamp, internet protocol (IP), and any clicks or number of clicks. Our main purpose is to track the user's movement on every webpage on the website, collect the geographical location or information, analyze the actual trend, and administer the website.</p>
<b className='mt-1'>Cookies and Web Beacons: </b>
<p>As per the other websites, NayeeDisha. in also uses 'cookies'. The purpose of these cookies is to collect or store the information/data including visitors' preferences and each webpage of the website where the visitor visited or accessed. We are using the website information the optimize the user experience or customize our website according to the user's point of view. </p>
<b className='mt-1'>Google DoubleClick DART Cookies:</b>
<p>We assumed that Google was the third-party vendor on our website. It also uses cookies called DART cookies. 
    We serve ads to our site users or visitors based upon their visit to www.website.com and other sites on the 
    internet. We follow the content network privacy policy where visitors can decide the use of the DART cooking
     after visiting the Google ads.</p>

<b className='mt-1'>Advertising Partners Privacy Policies: </b>
<p>The partner privacy policy applies to all the advertising partners of Nayeedisha. in. 
Third-party-based ad servers/networks use various technologies such as JavaScript, cookies, or Web Beacons that are used in their respective advertisements and links that appear on Nayeedisha. in, which are sent directly to users' browsers. 
Also, we are permitted to automatically receive your IP address or other effective technologies that help to build strong advertising campaigns or personalized advertising content that you can see on your website. 
Note that NayeeDisha. in has no access to or control over these cookies that are used by third-party advertisers.
</p>
<b className='mt-1'>Third Party Privacy Policies:</b>
<p>We at Nayeedisha.in introduce a privacy policy for third-party ads that do not apply to other advertisers or websites. Our privacy policy is based on the consultation for the third third-party ad servers or detailed information. It can include the instructions, practices, and opt-out of various options. 
Any visitor can disable the cookies via individual search engine or browser options. To get more information about cookie management with a particular search engine or web browser, it can be found at the respective web browser. 
</p>
<b className='mt-1'>CCPA Privacy Rights (Do Not Sell My Personal Information)</b>
<p>Under the California Consumer Privacy Act (CCPA), California consumers will get the right or liberty to:</p>
<ul>
    <li>Request that a business collect or acquire a consumer's personal information or disclose the categories and specific pieces of personal data that can be used for the business for the consumers. </li>
    <li>Request that the business delete any data or information related to the consumer that is collected for the business</li>
    <li>Request that a business can sell any personal information or data of the consumers.
        If you wish to submit any request so raise it to our helpdesk and we will respond to you within 1 month. Feel free to contact us using <a href='https://nayeedisha.in/contact-us' target="_blank">https://nayeedisha.in/contact-us</a> . 
</li>
</ul>

<b className='mt-1'>GDPR Data Protection Rights: </b>
<p>I hope you will be updated about the data protection rights. Here are the major points that have been desired such as: </p>
<ul>
    <li>Accessibility Right: you are requesting that we have copies of your information and we can charge a small amount of fee for this service. </li>
    <li>Rectification Right: You will get the right that we can modify correct or rectify any data or information if the information is incomplete or inaccurate. This is our responsibility to make it accurate or complete. </li>
    <li>Deletion Right: We are permitted to erase your personal information or data, under various conditions or situations. 
</li>
<li>Restriction Right: We are restricted to process any essential information or data, under certain situations or conditions. </li>
<li>
Right to Use Information: We can process our personal information under various conditions. 
</li>
<li>
Data Portability Right: we can transfer communicate or retrieve any data that we have collected from other sources, organizations, or other businesses. If you wish to submit any request so raise it to our helpdesk and we will respond to you within 1 month. Feel free to contact us using <a href="https://nayeedisha.in/contact-us" target="_blank">https://nayeedisha.in/contact-us</a> . 
</li>
</ul>
<b className='mt-1'>Children's Information: </b>
<p>Children's protection while using the internet is our major priority, we encourage the guardians or parents to observe monitor, or guide the participation of his/her children in their online activity. 
We at Nayeedisha. it doesn't collect or acquire any personal details or identifiable information from children under the age of 13. If someone is thinking that your child is getting information from our website, you must contact us on an immediate basis and we will try to give our best effort to remove such information from our website or records.
</p>

</div>
            

     
        </>
    );
};
export default PrivacyPolicy;